<script setup lang=ts>
const userStore = useUser()
const { isLogged, user } = storeToRefs(userStore)
</script>

<template>
  <div flex flex-col items-center justify-center>
    <div class="md:w-500px" p-12>
      <div v-if="isLogged" fubex-container max-w-2xl mx-auto flex flex-col justify-center items-center gap-24px my-80px>
        <div flex flex-col items-center>
          <div paragraph-md>
            Uživatel:
          </div>
          <div heading5 font-600>
            {{ user!.name }}
          </div>
        </div>
        <NuxtLink to="/profile/overview">
          <FButton name="login-account" size="lg" prefix-icon="i-fubex-user">
            Můj účet
          </FButton>
        </NuxtLink>
        <NuxtLink to="/logout">
          <FButton name="login-signout" size="lg" variant="text">
            Odhlásit
          </FButton>
        </NuxtLink>
      </div>
      <LoginForm v-else disable-links />
    </div>
  </div>
</template>
